import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AllRoute from "./navigation/AllRoutes";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{ backgroundColor: "#F6F9FC" }}>
        <AllRoute />
      </div>
    </LocalizationProvider>
  );
}

export default App;
