import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { commafy } from "../commafy";
import { formatDate } from "../utils/formatDate";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size\u00a0(km\u00b2)",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Density",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

export default function AllOrderTable({ order }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Styles = {
    tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
  };

  const navigate = useNavigate();

  const navigateToDetail = (row) => {
    navigate("/OrderDetail", {
      state: {
        productDetails: row,
      },
    });
  };

  return (
    <div style={{ width: "97%", background: "#F6F9FC" }}>
      <TableContainer
        style={{ background: "#F6F9FC", padding: "15px", width: "99%" }}
      >
        <Table style={{ borderSpacing: "0 15px", borderCollapse: "separate" }}>
          <TableHead>
            <TableRow>
              <TableCell style={Styles.tableHeading}>{"Ordenes"}</TableCell>
              <TableCell style={Styles.tableHeading}>{"Estado"}</TableCell>
              <TableCell style={Styles.tableHeading}>
                {"Fecha de compra"}
              </TableCell>
              <TableCell style={Styles.tableHeading}>{"Total"} </TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    onClick={() => {
                      navigateToDetail(row);
                    }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    style={{
                      background: "#fff",
                      margin: "15px 0",
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>
                      <div
                        style={{
                          color: "#444",
                          fontWeight: "600",
                          fontSize: 15,
                        }}
                      >
                        {row._id}
                      </div>
                    </TableCell>
                    {row.status === "Confirmando Pedido" && (
                      <TableCell>
                        <div
                          style={{
                            backgroundColor: "#CED7DF",
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            padding: 8,
                            textAlign: "center",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    {row.status === "Pedido Confirmado" && (
                      <TableCell>
                        <div
                          style={{
                            backgroundColor: "#E0F7E9",
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            padding: 8,
                            textAlign: "center",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    {row.status === "Procesando Pedido" && (
                      <TableCell>
                        <div
                          style={{
                            backgroundColor: "#E0F7E9",
                            color: "#65D068",
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            padding: 8,
                            textAlign: "center",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    {row.status === "Pedido Entregado" && (
                      <TableCell>
                        <div
                          style={{
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            padding: 8,
                            textAlign: "center",
                            backgroundColor: "#E0F7E9",
                            color: "#65D068",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    {row.status === "Cancelada" && (
                      <TableCell>
                        <div
                          style={{
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            padding: 8,
                            textAlign: "center",
                            backgroundColor: "#F9E1E6",
                            color: "#E74460",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    <TableCell style={{ color: "#444" }}>
                      {formatDate(row.purchaseDate)}
                    </TableCell>
                    <TableCell style={{ color: "#444" }}>
                      {`₡${commafy(row.totalPrice)}`}
                    </TableCell>

                    <TableCell>
                      <div style={{ color: "#444" }}>{row.nextImg}</div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={order.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
