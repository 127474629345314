import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
//@ts-ignore
import AdminNavbar from "../AdminComponents/AdminNavbar";
import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
//@ts-ignore
import AdminDashBoard from "../AdminComponents/AdminDashBoard";
import "./input.css";
import toast, { Toaster } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
//@ts-ignore
import axios from "../axios";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

const drawerWidth = 240;

const SettingScreen = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState<any>({});

  const notify = (e: any) => toast(e);

  const [activeTab, setActiveTabs] = useState("Configuraciones");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onUpdate = async (values: any) => {
    try {
      await axios.post("/api/v1/admin/settingScreen", {
        serviceFeeEnabled: values.serviceFeeEnabled,
        serviceFee: Number(values.serviceFee),
        smallOrderFeeEnabled: values.smallOrderFeeEnabled,
        minOrderSubtotal: Number(values.minOrderSubtotal),
        smallOrderFee: Number(values.smallOrderFee),
        prizes: values?.prizes?.split(",")?.map(Number),
        minimumPrizePurchases: Number(values.minimumPrizePurchases),
        checkInValue: Number(values.dailyCheckInPoints),
        basiCardReturn: Number(values.basiCardReturn),
      });
      notify("Settings updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error while updating settings");
    }
  };

  const getSettings = useCallback(async () => {
    try {
      const { data } = await axios.get(`/api/v1/admin/settings`);
      setSettings(data.settings);
      setLoading(false);
    } catch (error) {}
  }, []);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const drawer = (
    <Grid
      md={2}
      lg={2}
      sm={2}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <AdminDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Toaster />

      <AdminNavbar handleDrawerToggle={handleDrawerToggle} />
      <IconButton
        style={{
          marginLeft: 10,
          position: "absolute",
          zIndex: 100,
          top: 12,
          left: -10,
        }}
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { sm: "none", md: "none", lg: "none" } }}
      >
        <MenuIcon />
      </IconButton>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          height: "90vh",
          marginTop: 10,
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <h2>Setting</h2>
        <div className="w-1/2">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50} />
            </Box>
          ) : (
            <Formik
              initialValues={{
                serviceFeeEnabled: settings?.serviceFeeEnabled ?? true,
                serviceFee: settings?.serviceFee,
                smallOrderFeeEnabled: settings?.smallOrderFeeEnabled,
                minOrderSubtotal: settings?.minOrderSubtotal,
                smallOrderFee: settings?.smallOrderFee,
                prizes: settings?.prizes?.join(","),
                minimumPrizePurchases: settings?.minimumPrizePurchases,
                dailyCheckInPoints: settings?.checkInValue,
                basiCardReturn: settings?.basiCardReturn,
              }}
              onSubmit={onUpdate}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <div className="my-1">
                    <label>
                      <Field type="checkbox" name="serviceFeeEnabled" />
                      Enable Service Fee
                    </label>
                  </div>
                  <Field
                    disabled={!values.serviceFeeEnabled}
                    name="serviceFee"
                    label="Service fee"
                    component={TextField}
                    size="small"
                    className="!w-full !my-2"
                  />
                  <div className="my-1">
                    <label>
                      <Field type="checkbox" name="smallOrderFeeEnabled" />
                      Enable Small Order Fee
                    </label>
                  </div>
                  <Field
                    disabled={!values.smallOrderFeeEnabled}
                    name="minOrderSubtotal"
                    label="Min Order Subtotal"
                    component={TextField}
                    size="small"
                    className="!w-full !my-2"
                  />
                  <Field
                    disabled={!values.smallOrderFeeEnabled}
                    name="smallOrderFee"
                    label="Small Order fee"
                    component={TextField}
                    size="small"
                    className="!w-full !my-2"
                  />
                  <Field
                    name="prizes"
                    label="Prizes"
                    component={TextField}
                    size="small"
                    className="!w-full !my-2"
                  />
                  <Field
                    name="minimumPrizePurchases"
                    label="Prize minimum purchases"
                    component={TextField}
                    size="small"
                    className="!w-full !my-2"
                  />
                  <Field
                    name="dailyCheckInPoints"
                    label="Daily check-in points"
                    component={TextField}
                    size="small"
                    className="!w-full !my-2"
                  />
                  <Field
                    name="basiCardReturn"
                    label="Basi Card Return (%)"
                    component={TextField}
                    size="small"
                    className="!w-full !my-2"
                  />
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="mt-4 !bg-[#013d29] !text-white px-4 py-2 border-none cursor-pointer rounded hover:!bg-[#013d29d6]"
                  >
                    Guardar Cambios
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default SettingScreen;
