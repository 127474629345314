import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useStateValue } from "../ContextApi/StateProvider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

export default function VendorTable({ vendor }) {
  const navigation = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [{ cart }, dispatch] = useStateValue();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Styles = {
    tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
  };

  return (
    <div style={{ width: "99%", marginLeft: 10, background: "#F6F9FC" }}>
      <TableContainer style={{ background: "#F6F9FC", width: "97%" }}>
        <Table style={{ borderSpacing: "0 15px", borderCollapse: "separate" }}>
          <TableHead>
            <TableRow>
              <TableCell style={Styles.tableHeading}>{""}</TableCell>
              <TableCell style={Styles.tableHeading}>
                {"Nombre del Socio"}
              </TableCell>
              <TableCell style={Styles.tableHeading}>
                {"Nombre de la Tienda"}
              </TableCell>
              <TableCell style={Styles.tableHeading}>
                {"Estado del Socio"}
              </TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendor
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    onClick={() =>
                      navigation("/VendorDetailTable", {
                        state: {
                          detailData: row,
                        },
                      })
                    }
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    style={{
                      background: "#fff",
                      margin: "15px 0",
                      cursor: "pointer",
                    }}
                  >
                    <TableCell style={{ borderRadius: "10px 0 0 10px" }}>
                      <div>
                        <img
                          style={{ width: 30, height: 30 }}
                          src={row.image}
                        />
                      </div>
                    </TableCell>

                    <TableCell>
                      <div style={{ color: "#444", fontWeight: 600 }}>
                        {row.firstName}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ color: "#444", fontWeight: 600 }}>
                        {row.storeName}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={{ color: "#444", fontWeight: 600 }}>
                        {row.isActive === true ? <>Activo</> : <>Inactivo</>}
                      </div>
                    </TableCell>
                    <TableCell style={{ borderRadius: "0 10px 10px 0" }}>
                      <div style={{ color: "#444" }}>
                        <ArrowForwardIcon />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={vendor.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
