import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextField } from "formik-material-ui";
import axios from "../axios";
import "react-phone-number-input/style.css";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Creatable from "react-select/creatable";
import toast, { Toaster } from "react-hot-toast";
import FileInputField from "./FileInputField";

const addressScheme = Yup.object().shape({
  productName: Yup.string().required(" Nombre del Producto is required"),
  size: Yup.string().required(" Tamaño del Producto is required"),
  stock: Yup.string().required(" Stock del Producto is required"),
  price: Yup.string().required(" Precio del Producto is required"),
  images: Yup.string().required(" Image del Producto is required"),
  productDescription: Yup.string().required(
    "Descripción del Producto is required"
  ),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

function AddNewProduct() {
  const notify = (e) => toast(e);

  const [category, setCategory] = useState();
  const [allAisle, setAllAisle] = useState();
  const [aisle, setAisle] = React.useState("");
  const [allCategory, setAllCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [created, setCreated] = React.useState(false);

  function handleAisleCreated(opt, meta) {
    console.log(opt, meta);

    setAisle(opt);
    if (meta.action == "create-option") {
      setCreated(true);
    } else {
      getCategory(opt.label);
    }
  }

  const getCategory = async (val) => {
    let al = [];
    console.log(val, "inside function ");
    axios.get(`/api/v1/category/${val.toUpperCase()}`).then((res) => {
      console.log(res);
      res?.data?.cat.forEach((doc) => {
        al.push({ label: doc.label, value: doc.value });
      });
      console.log(al, "cat");
      setCategory(al);
    });
  };

  const handleCategoryget = async (opt, meta) => {
    setAllCategory(opt);
    let al = [];
    if (meta.action != "create-option") {
      console.log(opt, "inside function ");
      axios
        .get(`/api/v1/subCategory/${opt.label.toUpperCase()}`)
        .then((res) => {
          console.log(res);
          res?.data?.subCat.forEach((doc) => {
            al.push({ label: doc.label, value: doc.value });
          });
          console.log(al, "cat");
          setSubCategory(al);
        });
    }
  };

  const getAisle = async () => {
    let al = [];
    console.log("aisle");
    axios.get("/api/v1/aisle").then((res) => {
      console.log(res.data);
      res?.data?.aisle.forEach((doc) => {
        al.push({ label: doc.label, value: doc.value });
      });
      console.log(al);
      setAllAisle(al);
    });
  };
  useEffect(() => {
    getAisle();
  }, []);

  const handleSubmit = async (values) => {
    values.created = created;
    values.aisle = aisle?.label?.toUpperCase();
    values.category = allCategory?.label?.toUpperCase();
    values.subCategory = subCategory?.label?.toUpperCase();
    axios
      .post("/api/v1/product/new", {
        created: values.created,
        aisle: values.aisle,
        category: values.category,
        subCategory: values.subCategory,
        name: values.productName,
        size: values.size,
        stock: values.stock,
        price: values.price,
        images: values.images,
        description: values.productDescription,
      })
      .then((res) => {
        console.log(res.data, "mayresponsedata");
      });
    notify("New product created successfully");
  };
  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 10);
  };

  return (
    <Grid>
      <div>
        <Toaster />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Alert severity="success">Your product is created </Alert>
          </Box>
        </Modal>
      </div>
      <AdminNavbar />
      <Formik
        initialValues={{
          aisle: "",
          category: "",
          subCategory: "",
          productName: "",
          size: "",
          stock: "",
          price: "",
          images: "",
          productDescription: "",
        }}
        validationSchema={addressScheme}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          values,

          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <div style={{ marginTop: 100 }}>
            <div
              style={{
                fontSize: 16,
                fontWeight: "700",
                fontSize: 23,
                marginLeft: 5,
                color: "#4C9F6F",
              }}
            >
              Agregar Producto
            </div>
            <Form>
              <div
                style={{
                  padding: 20,
                  borderBottomWidth: 1,
                }}
              >
                <Field
                  size="small"
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="productName"
                  name="productName"
                  values={values.productName}
                  style={{
                    borderWidth: 1,
                    borderColor: "black",
                    width: "45%",
                    margin: 5,
                    marginBottom: 10,
                  }}
                  label="Nombre del Producto"
                  // variant="filled"
                />

                <Field
                  size="small"
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="size"
                  name="size"
                  values={values.size}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Tamaño del Producto"
                  // variant="filled"
                />
                <Field
                  size="small"
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="stock"
                  name="stock"
                  values={values.stock}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Stock del Producto"
                  // variant="filled"
                />
                <Field
                  size="small"
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="productDescription"
                  name="productDescription"
                  values={values.productDescription}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Descripción del Producto"
                  // variant="filled"
                />
                <Field
                  size="small"
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="price"
                  name="price"
                  values={values.price}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Precio del Producto"
                  // variant="filled"
                />

                <FileInputField name="images" />

                <FormControl sx={{ width: "45%", margin: 0.5 }}>
                  {allAisle && (
                    <Creatable
                      label="Seleccionar"
                      style={{ backgroundColor: "red" }}
                      options={allAisle}
                      placeholder={"Seleccionar Pasillo"}
                      onChange={(opt, meta) => handleAisleCreated(opt, meta)}
                    />
                  )}
                </FormControl>

                <FormControl sx={{ width: "45%", margin: 0.5 }}>
                  {category && (
                    <Creatable
                      options={category}
                      placeholder={"Seleccionar Categoría "}
                      onChange={(opt, meta) => handleCategoryget(opt, meta)}
                    />
                  )}
                </FormControl>
                <FormControl sx={{ width: "45%", margin: 0.5 }}>
                  {subCategory && (
                    <Creatable
                      options={subCategory}
                      placeholder={"Seleccionar Subcategoría"}
                      onChange={(opt, meta) => setSubCategory(opt)}
                    />
                  )}
                </FormControl>
                <button
                  type="submit"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#4C9F6F",
                    width: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    padding: 10,
                    color: "white",
                    borderWidth: 0,
                    marginTop: 30,
                  }}
                >
                  Agregar Producto
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </Grid>
  );
}

export default AddNewProduct;
