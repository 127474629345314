import * as React from "react";
import { Grid } from "@mui/material";
import { signOut } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { useStateValue } from "../ContextApi/StateProvider";
import "./Admin.css";
import { ArrowBackTwoTone } from "@material-ui/icons";
import { isMobile } from "react-device-detect";

export default function AdminNavbar({ handleDrawerToggle }) {
  const [{ admin }, dispatch] = useStateValue();
  const location = useLocation();

  const navigation = useNavigate();
  const addAdminToStore = () => {
    dispatch({
      type: "ADMIN_ADDED",
      item: null,
    });
  };
  const logOut = () => {
    // localStorage.removeItem("admin");
    signOut(auth);
    addAdminToStore();
  };

  return (
    <Grid
      container
      justifyContent={"space-between"}
      columns={{ xs: 11, sm: 12, md: 12, xl: 12, lg: 12 }}
      style={Styles.gridContainer}
    >
      <Grid style={Styles.imgContainer} item md={2} xs={5} xl={3}>
        {!isMobile && location?.pathname !== "/" && (
          <ArrowBackTwoTone
            style={{
              color: "black",
              fontSize: 22,
              cursor: "pointer",
            }}
            item
            onClick={() => navigation(-1)}
          />
        )}
        <img
          onClick={() => navigation("/")}
          alt="basimart"
          width={200}
          height={90}
          style={{ margin: -20, marginLeft: 15, cursor: "pointer" }}
          src={require("../assets/logo1.png")}
        />
      </Grid>
      <Grid
        style={{ display: "flex", flexDirection: "row-reverse" }}
        md={10}
        xs={6}
        xl={4}
        item
      >
        <div onClick={logOut} style={Styles.webLoginText}>
          Salir
        </div>
      </Grid>
    </Grid>
  );
}

const Styles = {
  gridContainer: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#4C9F6F",
    top: 0,
    position: "fixed",
    zIndex: 100,
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  webLoginText: {
    display: "flex",
    backgroundColor: "#2D634C",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    cursor: "pointer",
    color: "white",
  },
};
