import React, { useContext, useState, useEffect } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit'
import { Grid } from "@mui/material";
import axios from "../axios";
import CheckIcon from '@mui/icons-material/Check';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import moment from "moment/moment";
import { commafy } from "../commafy";

export default function BasiCardTable({transactions}) {
  const [apd, setApd] = useState(false)

  function handleApprove(userId, tid) {
    axios
    .put(`/api/v1/basiCard/approveTransaction/${userId}/${tid}`)
    .then((res) => {
      setApd(true)
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return (
    <>
    <div style={{ width: "100%", background: "#F6F9FC" }}>
      <MDBTable  responsive style={styles.mdTable}>           
        <MDBTableHead >
          <tr>
            <th scope='col' style={styles.subTittle}>{"Trasaction ID"}</th>
            <th scope='col' style={styles.subTittle}>{"UserName"} </th>
            <th scope='col' style={styles.subTittle}>{"Estado"}</th>
            <th scope='col' style={styles.subTittle}>{"Descripcion"}</th>
            <th scope='col' style={styles.subTittle}>  {"Fecha"} </th>
            <th scope='col' style={styles.subTittle}>{"Total"} </th>
            <th scope='col' style={styles.subTittle}>{"Comprobante"} </th>
            <th scope='col' style={styles.subTittle}>{"Aprobar"} </th>
            </tr>
          </MDBTableHead>
            {transactions.map((row) => {
              return(
                <>
                <MDBTableBody>
                  <tr style={{marginTop: '10px', marginButton:'10px', padding:'5px', cursor: 'pointer'}}>
                    <th  style={styles.tableTxt}>{row.transaction.id}</th>
                    <th  style={styles.tableTxt}>{row.userName}</th>
                    <th  style={styles.tableTxt}>
                      <p style={{color: row.transaction.status === 'completed' ? 'green' : 'orange'}}> {row.transaction.status}</p>

                    </th>
                    <th  style={styles.tableTxt}>{row.transaction.lastTransactionDescription}</th>
                    <th  style={styles.tableTxt}> 
                        {moment(new Date(row.transaction.lastTransactionDate)).format("DD/MM/YYYY")}                        
                    </th>
                    <th  style={styles.tableTxt}>₡{row.transaction.type}{commafy(row.transaction.lastTransaction)}</th>
                    <th> 
                    <>
                      {row.transaction.proofPayment &&(
                      <Zoom>
                          <img style={{ width: '40%', height: '40%' }} src={row.transaction.proofPayment} alt={'img'} />
                      </Zoom>
                      )}                                    
                   </>
                   </th>
                   <td> 
                      {row.transaction.status === 'pending' && (
                        <>
                          {row.transaction.proofPayment &&(
                          <CheckIcon  
                            onClick={ () => handleApprove(row.userId, row.transaction.id)}
                            style={{color: row.transaction.status === 'completed' ? 'green' : 'black'}}
                          /> 
                        )} 
                        </>
                      )}
                   </td>
                   </tr>
                   </MDBTableBody>
                   </>   )
                 })}
          </MDBTable>
          </div>
    </>
  );
}


const styles = { 
    tableHeading: { background: "#529f6f", height:'1.5px', width:'100%'},
    
    mdTable: { 
        background: "white", 
        borderSpacing: "40px 8px", 
        borderCollapse: "separate", 
        paddingLeft:'3px',
        marginLeft:'3px'  
    },
    mdTableMobile: { 
        background: "white", 
        borderSpacing: "20px 8px", 
        borderCollapse: "separate", 
        marginTop: '-65px'   
    },
    
    tableBody: { 
        color: "black",
        fontSize: 12,
        textAlign: 'center',
        margin: 'auto',
    },
    tableTxt: {
        fontSize: 12,
        color: "black",
        padding: "2px",
        margin: 'auto',
        textAlign: 'center',
        fontWeight:'200'
    
      },
      valueTxt: {
        fontSize: 12,
        color: "#222",
      },
      hightLightTxt: {
        fontSize: 12,
        color: "#AD6D49",
        margin: 0,
      },

    tittle: { 
        color: "#4B566B", 
        fontSize: 15, 
        fontWeight: "bold", 
        paddingLeft: '10px',
        marginTop: '20px',
      },

    tittleMobile: { 
        color: "#4B566B", 
        fontSize: 15, 
        fontWeight: "bold", 
        paddingLeft: '3px',
        marginTop: '10px',
      },

    subTittle: { 
        color: "#4B566B", 
        fontSize: 13, 
        fontWeight: "bold", 
        paddingLeft: '10px',
      },
      
      
    confirmando: {
        width: 76,
        height: 17,
        borderRadius: 5,
        backgroundColor: "#eae7f0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 11,
        padding: 15,
        textAlign: "center",
        fontWeight: 'bold',
        color: '#423363'
      },
      container: { 
        background: "#f2f2f4", 
        width:'100%', 
        borderRadius: 8,
        borderWidth: 2,
        borderColor: 'white',       
        padding: '10px',
        margin: '5px',  
        height:'160px'    
    },
    styleImg: {
      position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 300,
    bgcolor: "background.paper",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    }
  };