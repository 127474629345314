import React, { useContext, useState, useEffect } from "react";
import { Grid, Chip } from "@mui/material";
import axios from "../axios";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import AdminDashBoard from "../AdminComponents/AdminDashBoard";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import toast, { Toaster } from "react-hot-toast";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import FlagIcon from "@mui/icons-material/Flag";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import { commafy } from "../commafy";
import { formatDate } from "../utils/formatDate";

const drawerWidth = 240;

export default function BonusWeekly(props) {
  const { window } = props;
  const location = useLocation();
  const loading = location.state;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeTab, setActiveTabs] = useState("BonusWeekly");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const notify = (e) => toast(e);
  const [weeklyBonus, setWeeklyBonus] = useState("");
  const [weeklyChallenge, setWeeklyChallenge] = useState("");
  const [weeklyAmount, setWeeklyAmount] = useState("");
  const [getFee, setGetFee] = useState();
  const [pendingPayments, setPayments] = useState();

  const handleSetBonus = async () => {
    console.log(weeklyBonus);

    axios
      .post("/api/v1/admin/settingBonus", {
        bonus: weeklyBonus,
        challenge: weeklyChallenge,
        amount: weeklyAmount,
      })
      .then((res) => {
        axios.get(`/api/v1/admin/settings`).then((response) => {
          setGetFee(response?.data?.settings);
          setWeeklyBonus(response?.data?.settings?.weeklyBonus?.bonus);
          setWeeklyChallenge(response?.data?.settings?.weeklyBonus?.challenge);
          setWeeklyAmount(response?.data?.settings?.weeklyBonus?.amount);
          setEdit(true);
        });
      });
  };

  useEffect(() => {
    axios.get(`/api/v1/admin/settings`).then((res) => {
      setGetFee(res?.data?.settings);
      setWeeklyBonus(res?.data?.settings?.weeklyBonus?.bonus);
      setWeeklyChallenge(res?.data?.settings?.weeklyBonus?.challenge);
      setWeeklyAmount(res?.data?.settings?.weeklyBonus?.amount);
    });
  }, []);

  useEffect(() => {
    axios.get(`/api/v1/getBonusPayments`).then((res) => {
      setPayments(res?.data?.payments);
    });
  }, []);

  const handleWeeklyBonus = (e) => {
    setWeeklyBonus(e.target.value);
  };

  const handleWeeklyChallenge = (e) => {
    console.log("e", e.target.value);
    setWeeklyChallenge(e.target.value);
  };

  const handleWeeklyAmount = (e) => {
    console.log("e", e.target.value);
    setWeeklyAmount(e.target.value);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const drawer = (
    <Grid md={2} sx={12} style={{ width: "100%" }}>
      <div style={{ marginTop: 90 }}>
        <AdminDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [edit, setEdit] = useState(true);

  const handleEdit = () => {
    setEdit(false);
  };

  const handleSavePay = (id, vendorId) => {
    axios
      .put(`/api/v1/appovePayment`, {
        bonusId: id,
        vendorId: vendorId,
      })
      .then((res) => {
        axios.get(`/api/v1/getBonusPayments`).then((res) => {
          setPayments(res?.data?.payments);
        });
      });
  };

  return (
    <>
      <Box sx={{ display: "flex", background: "#F6F9FC", width: "100%" }}>
        <AdminNavbar handleDrawerToggle={handleDrawerToggle} />
        <IconButton
          style={{
            marginLeft: 10,
            position: "absolute",
            zIndex: 100,
            top: 12,
            left: -10,
          }}
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none", md: "none", lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            PaperProps={{
              sx: { height: "100%", background: "#F6F9FC" },
            }}
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            PaperProps={{
              sx: {
                height: "90%",
                marginTop: 10,
                borderWidth: 0,
                background: "#F6F9FC",
              },
            }}
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50} />
            </Box>
          ) : (
            <>
              <Grid
                item
                md={12}
                xs={12}
                style={{ minHeight: "90vh", background: "#F6F9FC" }}
              >
                <Grid md={12} xs={12}>
                  <h2
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      color: "#000",
                      marginTop: "100px",
                      marginBottom: "30px",
                    }}
                  ></h2>
                </Grid>
                <Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 30,
                    }}
                  >
                    {edit && (
                      <>
                        <table
                          style={{
                            height: "125px",
                            width: "85%",
                            background: "white",
                            borderRadius: 10,
                            borderColor: "gray",
                          }}
                        >
                          <h2
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "#2d313c",
                              margin: 8,
                            }}
                          >
                            Bonus Actual
                          </h2>
                          <tr>
                            <td>
                              <p style={{ fontSize: "14px" }}>
                                <CardGiftcardIcon /> Bonus:{" "}
                                <strong>
                                  ₡{commafy(getFee?.WeeklyBonus?.bonus)}
                                </strong>
                              </p>
                            </td>

                            <td>
                              <p style={{ fontSize: "14px" }}>
                                <FlagIcon /> Vender Goal:{" "}
                                <strong>
                                  {" "}
                                  {getFee?.WeeklyBonus?.challenge}{" "}
                                </strong>
                                ventas
                              </p>
                            </td>

                            <td>
                              <p style={{ fontSize: "14px" }}>
                                <CardGiftcardIcon /> Amount:{" "}
                                <strong>
                                  ₡{commafy(getFee?.WeeklyBonus?.amount)}
                                </strong>
                              </p>
                            </td>

                            <td>
                              <p style={{ fontSize: "14px" }}>
                                <PublishedWithChangesIcon /> Estado:{" "}
                                <strong> {getFee?.WeeklyBonus?.status} </strong>
                              </p>
                            </td>

                            <td>
                              <Fab
                                style={{
                                  backgroundColor: "#4C9F6F",
                                  color: "white",
                                }}
                                aria-label="edit"
                                onClick={() => handleEdit()}
                              >
                                <EditIcon />
                              </Fab>
                            </td>
                          </tr>
                        </table>
                      </>
                    )}

                    {!edit && (
                      <table
                        style={{
                          height: "125px",
                          width: "85%",
                          background: "white",
                          borderRadius: 10,
                          borderColor: "gray",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#2d313c",
                            margin: 8,
                          }}
                        >
                          Bonus Actual
                        </h2>
                        <tr>
                          <td>
                            <p style={{ fontSize: "14px" }}>
                              <CardGiftcardIcon /> Bonus:{" "}
                              <strong>
                                ₡
                                <TextField
                                  variant="standard"
                                  type="number"
                                  defaultValue={getFee?.WeeklyBonus?.bonus}
                                  onChange={handleWeeklyBonus}
                                  size="small"
                                  style={{
                                    width: "80px",
                                    paddingLeft: "12px",
                                    fontSize: "14px",
                                    color: "gray",
                                  }}
                                />
                              </strong>
                            </p>
                          </td>

                          <td>
                            <p style={{ fontSize: "14px" }}>
                              <FlagIcon /> Vender Goal:{" "}
                              <strong>
                                <TextField
                                  variant="standard"
                                  type="number"
                                  defaultValue={getFee?.WeeklyBonus?.challenge}
                                  onChange={handleWeeklyChallenge}
                                  size="small"
                                  style={{
                                    width: "32px",
                                    paddingLeft: "12px",
                                    fontSize: "14px",
                                    color: "gray",
                                  }}
                                />
                              </strong>{" "}
                              ventas
                            </p>
                          </td>

                          <td>
                            <p style={{ fontSize: "14px" }}>
                              <FlagIcon /> Amount:{" "}
                              <strong>
                                <TextField
                                  variant="standard"
                                  type="number"
                                  defaultValue={getFee?.WeeklyBonus?.amount}
                                  onChange={handleWeeklyAmount}
                                  size="small"
                                  style={{
                                    width: "60px",
                                    paddingLeft: "12px",
                                    fontSize: "14px",
                                    color: "gray",
                                  }}
                                />
                              </strong>{" "}
                            </p>
                          </td>

                          <td>
                            <p style={{ fontSize: "14px" }}>
                              <PublishedWithChangesIcon /> Estado:{" "}
                              <strong> {getFee?.WeeklyBonus?.status} </strong>
                            </p>
                          </td>

                          <td>
                            <Fab
                              style={{
                                backgroundColor: "#4C9F6F",
                                color: "white",
                              }}
                              aria-label="edit"
                              onClick={() => handleSetBonus()}
                            >
                              <SaveIcon />
                            </Fab>
                          </td>
                        </tr>
                      </table>
                    )}
                  </div>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <Grid
                    container
                    style={{
                      width: "85%",
                      background: "white",
                      borderRadius: 10,
                      borderColor: "gray",
                      marginTop: "10px",
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#2d313c",
                        margin: "18px",
                      }}
                    >
                      Pagos Pendientes
                    </h2>

                    {pendingPayments?.map((row) => {
                      if (row.bonusPayment.length > 0) {
                        return (
                          <>
                            <Grid
                              container
                              xs={12}
                              md={12}
                              style={{ margin: "5px", padding: "10px" }}
                            >
                              <Grid item xs={12} md={12}>
                                <Accordion
                                  expanded={expanded === row.storeName}
                                  onChange={handleChange(row.storeName)}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id={row.storeName}
                                  >
                                    <p style={{ fontSize: "14px" }}>
                                      User: <strong> {row.firstName} </strong>
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        paddingLeft: "30px",
                                      }}
                                    >
                                      Store: <strong> {row.storeName} </strong>
                                    </p>
                                  </AccordionSummary>
                                  <AccordionDetails id={row.storeName}>
                                    {row.bonusPayment?.map((pay) => {
                                      if (pay.status === "pending") {
                                        return (
                                          <>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              style={{
                                                border: "1px solid gray",
                                                padding: "10px",
                                                margin: "3px",
                                                borderRadius: "10px",
                                              }}
                                            >
                                              <table
                                                style={{
                                                  margin: "auto",
                                                  width: "100%",
                                                }}
                                              >
                                                <tr>
                                                  <td
                                                    style={{
                                                      fontSize: "13px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {" "}
                                                    Total a Pagar{" "}
                                                  </td>

                                                  <td
                                                    style={{
                                                      fontSize: "13px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {" "}
                                                    Fecha{" "}
                                                  </td>

                                                  <td
                                                    style={{
                                                      fontSize: "13px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {" "}
                                                    Status{" "}
                                                  </td>

                                                  <td
                                                    style={{
                                                      fontSize: "13px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {" "}
                                                    Completar{" "}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    {" "}
                                                    {pay.total}{" "}
                                                  </td>

                                                  <td
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    {formatDate(pay.create_at)}
                                                  </td>

                                                  <td
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    {" "}
                                                    {pay.status}{" "}
                                                  </td>

                                                  {pay?.status ===
                                                    "pending" && (
                                                    <td id={pay.id}>
                                                      {" "}
                                                      <CheckIcon
                                                        style={{
                                                          fontSize: "18px",
                                                          fontWeight: "bold",
                                                          cursor: "pointer",
                                                          color: "green",
                                                        }}
                                                        id={pay.id}
                                                        onClick={() =>
                                                          handleSavePay(
                                                            pay.id,
                                                            row.vendorId
                                                          )
                                                        }
                                                      />
                                                    </td>
                                                  )}
                                                </tr>
                                              </table>
                                            </Grid>
                                          </>
                                        );
                                      }
                                      return <></>;
                                    })}
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            </Grid>
                          </>
                        );
                      }
                      return <></>;
                    })}
                  </Grid>
                </div>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

const styles = {
  tableHeading: { background: "#529f6f", height: "1.5px", width: "100%" },

  mdTable: {
    background: "white",
    borderSpacing: "50px 8px",
    borderCollapse: "separate",
    paddingLeft: "5px",
    marginLeft: "5px",
  },
  mdTableMobile: {
    background: "white",
    borderSpacing: "20px 8px",
    borderCollapse: "separate",
    marginTop: "-65px",
  },

  tableBody: {
    color: "black",
    fontSize: 12,
    textAlign: "center",
    margin: "auto",
  },
  tableTxt: {
    fontSize: 12,
    color: "black",
    padding: "2px",
    margin: "auto",
    textAlign: "center",
    fontWeight: "200",
  },
  valueTxt: {
    fontSize: 12,
    color: "#222",
  },
  hightLightTxt: {
    fontSize: 12,
    color: "#AD6D49",
    margin: 0,
  },

  tittle: {
    color: "#4B566B",
    fontSize: 15,
    fontWeight: "bold",
    paddingLeft: "10px",
    marginTop: "20px",
  },

  tittleMobile: {
    color: "#4B566B",
    fontSize: 15,
    fontWeight: "bold",
    paddingLeft: "3px",
    marginTop: "10px",
  },

  subTittle: {
    color: "#4B566B",
    fontSize: 13,
    fontWeight: "bold",
    paddingLeft: "10px",
  },

  confirmando: {
    width: 76,
    height: 17,
    borderRadius: 5,
    backgroundColor: "#eae7f0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 11,
    padding: 15,
    textAlign: "center",
    fontWeight: "bold",
    color: "#423363",
  },
  container: {
    background: "#f2f2f4",
    width: "100%",
    borderRadius: 8,
    borderWidth: 2,
    borderColor: "white",
    padding: "10px",
    margin: "5px",
    height: "160px",
  },
  styleImg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 300,
    bgcolor: "background.paper",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  chip: {
    backgroundColor: "gray",
    color: "white",
    marginRight: "10px",
  },
  chipPress: {
    backgroundColor: "green",
    color: "white",
    marginRight: "10px",
  },
};
