import React, { useCallback } from "react";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import "react-phone-number-input/style.css";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useField, FieldConfig } from "formik";

export const FileInputField = ({
  children,
  onChange,
  ...props
}: FieldConfig<string> & {
  disabled?: boolean;
  onChange?: (v: any) => void;
  accept?: string;
  className?: string;
}) => {
  const [, { value, touched, error }, { setValue }] = useField(props);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files) return;
      const file = e.currentTarget.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        setValue(e.target.result);
        if (onChange) {
          onChange(e.target.result as any);
        }
      };
    },
    [setValue, onChange]
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: 23,
      }}
    >
      {value && (
        <Avatar
          src={value}
          style={{
            display: "flex",
            width: 100,
            height: 100,
            alignSelf: "center",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            justifySelf: "center",
          }}
        />
      )}

      <input
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleChange}
        id={props.name}
      />
      <label htmlFor={props.name} style={{ alignSelf: "center" }}>
        <IconButton style={{ backgroundColor: "lightgray" }} component="span">
          <AddPhotoAlternateIcon />
        </IconButton>
      </label>

      {touched && error && (
        <p
          className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root"
          id="price-helper-text"
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default FileInputField;
