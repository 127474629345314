import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AllOrderTable from "../components/AllOrderTable";

import AdminNavbar from "../AdminComponents/AdminNavbar";
import React, { useState } from "react";
import AdminDashBoard from "../AdminComponents/AdminDashBoard";
import AdminMoneyStatus from "../AdminComponents/AdminMoneyStatus";
import AdminBarChart from "../AdminComponents/AdminBarChart";
import AdminPieChart from "../AdminComponents/AdminPieChart";
import VendorTable from "../VendorComponents/VendorTable";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/Wallet";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import Colors from "../Theme/Colors";
import AdminOrderStatus from "../AdminComponents/AdminOrderStatus";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import io from "socket.io-client";
import axios from "../axios";
import { useEffect } from "react";
const drawerWidth = 240;
const status = [
  {
    id: 1,
    type: "Confirmando Pedido",
  },
  {
    id: 2,
    type: "Pedido Confirmado",
  },
  {
    id: 3,
    type: "Procesando Pedido",
  },
  {
    id: 4,
    type: "Pedido Entregado",
  },
  {
    id: 5,
    type: "Cancelada",
  },
];
function OrderScreen(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [orderStatus, setOrderStatus] = useState();
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigation = useNavigate();
  const handleStatus = async (e) => {
    console.log(e, "aisle");
    setOrderStatus(e.target.value);
    setLoading(true);
    const req = await axios.post(`/api/v1/allOrdersDetails/filter/status`, {
      type: e.target.value,
    });
    console.log(req.data.order, "orders");
    setOrder(req?.data?.allOrdersDetail);
    setLoading(false);
  };
  const [activeTab, setActiveTabs] = useState("Ordenes");
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState();
  console.log(order, "myfetchorder");
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const req = await axios.post("/api/v1/allOrdersDetails/filter/status");
      setOrder(req.data.allOrdersDetail);
      setLoading(false);
    }
    const socket = io(
      "https://urchin-app-49cxg.ondigitalocean.app/api/socket",
      {
        transports: ["websocket"],
      }
    );
    socket.on("newThought", (thought) => {
      console.log(thought, "hello");
      fetchData();
    });
    fetchData();
  }, []);
  const drawer = (
    <Grid
      md={2}
      lg={2}
      sm={2}
      sx={12}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <AdminDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", background: "#F6F9FC", width: "100%" }}>
      <AdminNavbar handleDrawerToggle={handleDrawerToggle} />
      <IconButton
        style={{
          marginLeft: 10,
          position: "absolute",
          zIndex: 100,
          top: 12,
          left: -10,
        }}
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { sm: "none", md: "none", lg: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid item md={12} lg={10} sm={12} xs={12}>
            <Grid>
              <Grid md={12} xs={12}>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#000",
                    marginTop: 100,
                    marginBottom: -20,
                  }}
                >
                  Ordenes
                </h2>
              </Grid>
              <Grid
                style={{ width: "20%", padding: 20 }}
                md={2}
                xs={4}
                lg={2}
                sm={2}
                xl={2}
              >
                <InputLabel id="aisle" style={{ padding: 10 }}>
                  Estado del pedido
                </InputLabel>
                <Select
                  displayEmpty={false}
                  id="aisle"
                  value={orderStatus}
                  onChange={handleStatus}
                  label="Estado del pedido"
                  style={{ width: "100%" }}
                >
                  {status.map((item) => (
                    <MenuItem value={item.type}>{item.type}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                style={{ display: "flex", justifyContent: "flex-end" }}
                md={12}
                xs={12}
              ></Grid>
              <Grid item md={12} lg={10} sm={12} xs={12}>
                <AllOrderTable order={order} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

OrderScreen.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default OrderScreen;

const balanceStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  todayOrders: {
    backgroundColor: Colors.seaGray,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  MonthlyOrders: {
    backgroundColor: Colors.seaBlue,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  totalOrders: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  iconStyle: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  textStyle: { color: "white", fontSize: 17, fontWeight: "600" },
  balanceStyle: { color: "#4B566B", fontSize: 25, fontWeight: "700" },
};
const orderStyles = {
  orderMainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  orderChildContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: "flex",

    alignItems: "center",
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  iconStyleTotal: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#F7DABD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStylePending: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#C3DDFD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleProcessing: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#AFECEF",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleDelivered: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#BCF0DA",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  orderTextStyle: {
    color: "black",
    fontSize: 14,
    fontWeight: "600",
  },
  orderQuantity: { color: "#4B566B", fontSize: 17, fontWeight: "700" },
};
