import * as React from "react";
import Avatar from "@mui/material/Avatar";
import toast, { Toaster } from "react-hot-toast";
import { ToggleSlider } from "react-toggle-slider";
import { Field, Formik, Form } from "formik";
import { useLocation } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import { useState } from "react";
import { Grid } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from "../axios";
import { List, ListItem, ListItemIcon } from "@mui/material";
import Location from "../AdminComponents/Location";
import { Edit } from "@material-ui/icons";
import Colors from "../Theme/Colors";
import { useEffect } from "react";
import { WarehouseOutlined } from "@mui/icons-material";
import { Button } from "react-bootstrap";

export default function AllProductTable() {
  const location = useLocation();
  const notify = (e) => toast(e);
  const vendorData = location.state.detailData;

  const [status, setStatus] = useState(vendorData.isActive);
  const handleStatus = async () => {
    console.log(vendorData, "vendor");
    console.log(status);
    setStatus(!status);
  };
  const [warehouse, setWarehouse] = useState([]);
  const [warehouseTrigger, setWarehouseTriger] = useState(vendorData.wId);
  async function fetchData() {
    const req = await axios.post("/api/v1/warehouse/vendorWarehouse", {
      warehouseIds: warehouseTrigger,
    });
    console.log(req.data, "All warehouses");
    setWarehouse(req.data);
  }
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseTrigger]);
  const [aisle, setAisle] = React.useState("");
  const [wId, setWId] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [deliveryData, setDeliveryData] = useState({
    priority: "",
    deliveryTimePriority: "",
    deliveryPricePriority: "",
    prioritySchedule: "allDay",
    priorityTime: ["", ""],

    standard: "",
    deliveryTimeStandard: "",
    deliveryPriceStandard: "",
    deliverySchedule: "allDay",
    deliveryTime: ["", ""],

    faster: "",
    deliveryTimeFaster: "",
    deliveryPriceFaster: "",
    fasterSchedule: "allDay",
    fasterTime: ["", ""],
  });

  const deliveryTable = [
    {
      name: "Priority",
      enabled: "priority",
      deliveryTime: "deliveryTimePriority",
      deliveryPrice: "deliveryPricePriority",
      schedule: "prioritySchedule",
      time: "priorityTime",
    },
    {
      name: "Standard",
      enabled: "standard",
      deliveryTime: "deliveryTimeStandard",
      deliveryPrice: "deliveryPriceStandard",
      schedule: "deliverySchedule",
      time: "deliveryTime",
    },
    {
      name: "Faster",
      enabled: "faster",
      deliveryTime: "deliveryTimeFaster",
      deliveryPrice: "deliveryPriceFaster",
      schedule: "fasterSchedule",
      time: "fasterTime",
    },
  ];

  const [vendorGet, setVendorGet] = useState();
  const [snipe, setSnipe] = useState();
  const [transefer, setTransfer] = useState();
  const [onChash, setOnCash] = useState();
  const [snipeChecked, setSnipeChecked] = useState();
  const [transferChecked, setTransferChecked] = useState();
  const [onDeliveryChecked, setOnDeliveryChecked] = useState();
  const handleUploadSimpe = () => {
    setSnipeChecked(!snipeChecked);
  };
  const handleUploadTransfer = () => {
    setTransferChecked(!transferChecked);
  };
  const handleUploadOnDelivery = () => {
    setOnDeliveryChecked(!onDeliveryChecked);
  };

  useEffect(() => {
    const fetchVendor = () => {
      axios.get(`/api/v1/vendor/${vendorData?._id}`).then((res) => {
        console.log(res.data.vendorId, "vendor Data");
        // if(res.data.vendorId?.adminPaymentType)
        setSnipe(res.data.vendorId?.adminPaymentType);
        setTransfer(res.data.vendorId?.adminPaymentType);
        setOnCash(res.data.vendorId?.adminPaymentType);
        setSnipeChecked(res.data.vendorId?.adminPaymentType?.snipeChecked);
        setTransferChecked(
          res.data.vendorId?.adminPaymentType?.transferChecked
        );
        setOnDeliveryChecked(
          res.data.vendorId?.adminPaymentType?.onDeliveryChecked
        );
        setDeliveryData(res.data.vendorId?.adminDeliveryType);
        setVendorGet(res.data.vendorId);
      });
    };
    fetchVendor();
  }, [vendorData]);

  const [userLoc, setUserLoc] = useState(vendorData.address);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleLocation = (data) => {
    console.log(data);
    setUserLoc(data);
    closeModal();
  };

  //assign warehouse to vendor
  const handleAisle = async (e) => {
    console.log(e, "aisle");
    setAisle(e.target.value);
    warehouse.allWarehouse.forEach((doc) => {
      if (e.target.value == doc.warehouseName) {
        console.log(doc);

        setWId(doc._id);
        axios
          .put(`/api/v1/assignWarehouse/${vendorData._id}`, { id: doc._id })
          .then((res) => {
            console.log(res);
            setWarehouseTriger(doc._id);
            notify("Vendor assigned successfully");
          });
      }
    });
    console.log(wId);
  };

  const [storeImage, setStoreImage] = useState(null);
  const [img, setImg] = useState(null);
  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(event.target.files[0]);
        setStoreImage(e.target.result);
      };
    }
  };

  const handleSubmit = async (values) => {
    values.img = img ? img : vendorData.image;
    values.commission =
      values.commission == "" ? vendorData.commission : values.commission;
    console.log(values, status, "valuesvaluesvaluesvalues");
    axios
      .put(
        `/api/v1/vendorImgUpdate/${vendorData?._id}`,
        {
          image: values.img,
          cellPhone: values.cellPhone,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          address: JSON.stringify(userLoc),
          documentNumber: values.documentNumber,
          guy: values.guy,
          isActive: status,
          storeName: values.storeName,
          commission: values.commission,
          adminDeliveryType: JSON.stringify(deliveryData),
          adminPaymentType: JSON.stringify({
            snipeChecked: snipeChecked,
            transferChecked: transferChecked,
            onDeliveryChecked: onDeliveryChecked,
          }),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status === 202) {
          console.log("alreadyRegistered");
          notify("Store name already exist");
        } else {
          notify("Updated successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeliverySubmit = async () => {
    console.log("hello");
    let v = {
      snipeChecked: snipeChecked,
      transferChecked: transferChecked,
      onDeliveryChecked: onDeliveryChecked,
    };
    axios
      .put(`/api/v1/vendorImgUpdate/${vendorData?._id}`, {
        image: vendorData.image,
        storeName: vendorGet.storeName,
        adminDeliveryType: JSON.stringify(deliveryData),
        address: JSON.stringify(userLoc),
        adminPaymentType: JSON.stringify(v),
      })
      .then((res) => {
        console.log(res);
        notify("Updated successfully");
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("hogai");
  };
  const handlePaymentSubmit = async (values) => {
    let v = {
      snipeChecked: snipeChecked,
      transferChecked: transferChecked,
      onDeliveryChecked: onDeliveryChecked,
    };
    axios
      .put(`/api/v1/vendorImgUpdate/${vendorData?._id}`, {
        image: vendorData.image,
        storeName: vendorGet.storeName,
        adminPaymentType: JSON.stringify(v),
        address: JSON.stringify(userLoc),
        adminDeliveryType: JSON.stringify(deliveryData),
      })
      .then((res) => {
        console.log(res);
        notify("Updated successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Styles = {
    tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
    customOverlay: {
      borderRadius: 10,
    },
    customModal: {
      borderRadius: 10,

      minHeight: "0%",
      minWidth: "10%",
      maxWidth: "80%",
    },
  };

  return (
    <div
      style={{
        // display: "flex",
        width: "100%",
        background: "#F6F9FC",
        minHeight: "100vh",
      }}
    >
      <AdminNavbar />
      <Toaster />
      <div style={{ paddingBottom: 100 }}></div>
      <div>
        <Modal
          // isOpen={modalIsOpen}
          // onRequestClose={closeModal}
          open={modalIsOpen}
          onClose={closeModal}
          center
          styles={{
            overlay: Styles.customOverlay,
            modal: Styles.customModal,
          }}
        >
          <Location
            handleTab={(e) => handleLocation(e)}
            getLocation={() => {
              console.log("hello");
            }}
          />
        </Modal>
      </div>
      <Grid
        container
        md={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          md={8}
          xs={12}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            borderRadius: 10,
            padding: 10,
            marginBottom: 50,
          }}
        >
          <div
            style={{
              color: "black",
              fontWeight: "700",
              fontSize: 17,
              padding: 10,
            }}
          >
            Asignar Almacén
          </div>
          {warehouse && (
            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              lg={12}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20,
                paddingTop: 20,
                flexDirection: "row",
              }}
            >
              <Grid item md={6} lg={6} sm={12} xs={12} xl={6}>
                <FormControl sx={{ width: "80%" }}>
                  <InputLabel id="aisle">
                    Asignar un Socio a un Almacén
                  </InputLabel>
                  <Select
                    displayEmpty={false}
                    id="aisle"
                    value={aisle}
                    onChange={handleAisle}
                    label="Asignar un Socio a un Almacén"
                    style={{ width: "100%" }}
                  >
                    {warehouse?.allWarehouse?.map((item) => (
                      <MenuItem
                        // onClick={() => setCategory(item.values)}
                        value={item.warehouseName}
                      >
                        {item.warehouseName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={6} lg={6} sm={12} xs={12} xl={6}>
                {warehouse?.vendorWarehouse && (
                  <Grid>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      {warehouse?.vendorWarehouse?.map((item) => (
                        <ListItem disablePadding>
                          {" "}
                          <ListItemIcon>
                            <WarehouseOutlined
                              style={{ color: Colors.lightGreen }}
                            />
                          </ListItemIcon>
                          {item.warehouseName}
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {vendorGet && (
            <Formik
              initialValues={{
                firstName: vendorGet?.firstName,
                lastName: vendorGet?.lastName,
                storeName: vendorGet?.storeName,
                birthDate: vendorGet?.birthDate,
                cellPhone: vendorGet?.cellPhone,
                documentNumber: vendorGet?.documentNumber,
                email: vendorGet?.email,
                guy: vendorGet?.guy,
                sampleImg: "",
                commission: vendorGet?.commission ? vendorGet.commission : "",
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ values, handleChange }) => (
                <div>
                  <div
                    style={{
                      color: "black",
                      fontWeight: "700",
                      fontSize: 17,
                      padding: 10,
                    }}
                  >
                    Información Personal del Socio
                  </div>
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        justifySelf: "center",
                        width: "100%",
                        marginBottom: 23,
                      }}
                    >
                      <Avatar
                        src={storeImage ? storeImage : vendorData.image}
                        style={{
                          display: "flex",
                          width: 100,
                          height: 100,
                          alignSelf: "center",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          justifySelf: "center",
                          border: `2px solid ${Colors.lightGreen}`,
                        }}
                      />
                      <div style={{ margin: "0 10px" }}>
                        <label htmlFor="file-input">
                          <Edit
                            style={{
                              cursor: "pointer",
                              color: Colors.lightGreen,
                            }}
                          />
                        </label>
                        <input
                          style={{ display: "none" }}
                          id="file-input"
                          type="file"
                          accept="image/*"
                          onChange={handleChangeImage}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        padding: 4,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Nombre del Socio:{" "}
                      </div>

                      <Field
                        type="text"
                        id="firstName"
                        name="firstName"
                        style={{
                          borderRadius: 3,
                          padding: 4,
                          borderWidth: 0,
                          border: `1px solid ${Colors.lightGreen}`,
                        }}
                        value={values.firstName}
                        onChange={handleChange}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Apellido del Socio:
                      </div>

                      <input
                        id="lastName"
                        name="lastName"
                        onChange={handleChange}
                        value={values.lastName}
                        style={{
                          borderRadius: 3,
                          padding: 4,
                          borderWidth: 0,
                          border: `1px solid ${Colors.lightGreen}`,
                        }}
                        type="text"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Nombre de la tienda del proveedor:
                      </div>
                      <input
                        id="storeName"
                        name="storeName"
                        style={{
                          borderRadius: 3,
                          padding: 4,
                          borderWidth: 0,
                          border: `1px solid ${Colors.lightGreen}`,
                        }}
                        onChange={handleChange}
                        value={values.storeName}
                        type="text"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Fecha de nacimiento del Socio
                      </div>
                      <input
                        id="birthDate"
                        name="birthDate"
                        style={{
                          borderRadius: 3,
                          padding: 4,
                          borderWidth: 0,
                          border: `1px solid ${Colors.lightGreen}`,
                        }}
                        value={values.birthDate}
                        onChange={handleChange}
                        type="text"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Número de teléfono del Socio:
                      </div>
                      <input
                        id="cellPhone"
                        name="cellPhone"
                        style={{
                          borderRadius: 3,
                          padding: 4,
                          borderWidth: 0,
                          border: `1px solid ${Colors.lightGreen}`,
                        }}
                        value={values.cellPhone}
                        onChange={handleChange}
                        type="text"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Número de documento del Socio:
                      </div>
                      <input
                        id="documentNumber"
                        name="documentNumber"
                        style={{
                          borderRadius: 3,
                          padding: 4,
                          borderWidth: 0,
                          border: `1px solid ${Colors.lightGreen}`,
                        }}
                        onChange={handleChange}
                        value={values.documentNumber}
                        type="text"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Correo electrónico del Socio:
                      </div>
                      <input
                        id="email"
                        name="email"
                        style={{
                          borderRadius: 3,
                          padding: 4,
                          borderWidth: 0,
                          border: `1px solid ${Colors.lightGreen}`,
                        }}
                        onChange={handleChange}
                        value={values.email}
                        type="text"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Tipo de documento del Socio:
                      </div>
                      <input
                        id="guy"
                        name="guy"
                        style={{
                          borderRadius: 3,
                          padding: 4,
                          borderWidth: 0,
                          border: `1px solid ${Colors.lightGreen}`,
                        }}
                        value={values.guy}
                        onChange={handleChange}
                        type="text"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Porcentaje de Comisión para el Socio:
                      </div>
                      <input
                        id="commission"
                        name="commission"
                        style={{
                          borderRadius: 3,
                          padding: 4,
                          borderWidth: 0,
                          border: `1px solid ${Colors.lightGreen}`,
                        }}
                        value={values.commission}
                        onChange={handleChange}
                        type="text"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 4,
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          color: Colors.lightGreen,
                          width: 200,
                        }}
                      >
                        Dirección del proveedor:
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {userLoc && (
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: Colors.lightGreen,
                              width: 150,
                            }}
                          >
                            {userLoc.loca}
                          </div>
                        )}
                        <Edit
                          onClick={openModal}
                          style={{
                            cursor: "pointer",
                            color: Colors.lightGreen,
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: "space-around",
                      }}
                    >
                      <ToggleSlider
                        active={vendorGet?.isActive}
                        onToggle={handleStatus}
                        draggable={true}
                      />

                      <div
                        style={{
                          color: status ? "#2D634C" : "red",
                          marginLeft: 5,
                          fontSize: 16,
                          fontWeight: "600",
                        }}
                      >
                        {status ? "Active" : "Inactive"}
                      </div>
                    </div>

                    <button
                      type="submit"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#4C9F6F",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 10,
                        padding: 10,
                        color: "white",
                        borderWidth: 0,
                        marginTop: 10,
                        marginLeft: 40,
                      }}
                    >
                      Guardar Cambios
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          )}
          <div
            style={{
              color: "black",
              fontWeight: "700",
              fontSize: 17,
              padding: 10,
            }}
          >
            Datos bancarios del Socio
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 4,
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                fontSize: 16,
                fontWeight: "600",
                color: Colors.lightGreen,
                width: 200,
              }}
            >
              Nombre de la Cuenta
            </div>

            <div
              style={{
                fontSize: 14,
                fontWeight: "600",
                color: Colors.lightGreen,
                width: 150,
              }}
            >
              {vendorData?.bankDetail?.accountName
                ? vendorData?.bankDetail?.accountName
                : "No hay detalles"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 4,
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                fontSize: 16,
                fontWeight: "600",
                color: Colors.lightGreen,
                width: 200,
              }}
            >
              Número de IBAN
            </div>

            <div
              style={{
                fontSize: 14,
                fontWeight: "600",
                color: Colors.lightGreen,
                width: 150,
              }}
            >
              {vendorData?.bankDetail?.accountNumber
                ? vendorData?.bankDetail?.accountNumber
                : "No hay detalles"}
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
          >
            <table>
              <tr
                style={{
                  textAlign: "left",
                }}
              >
                <th>Habilitar</th>
                <th>Tipo</th>
                <th>Tiempo de Entrega</th>
                <th style={{ width: 50 }}>Precio</th>
                <th>Disponibilidad</th>
                <th style={{ width: 230 }}></th>
              </tr>
              {vendorGet &&
                deliveryTable.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <ToggleSlider
                        active={deliveryData[item.enabled]}
                        onToggle={(v) =>
                          setDeliveryData({
                            ...deliveryData,
                            [item.enabled]: v,
                          })
                        }
                        draggable={true}
                      />
                    </td>

                    <td>{item.name}</td>
                    <td>
                      <input
                        className="formInput"
                        value={deliveryData[item.deliveryTime]}
                        onChange={(e) =>
                          setDeliveryData({
                            ...deliveryData,
                            [item.deliveryTime]: e.target.value,
                          })
                        }
                        style={{
                          borderWidth: 0,
                          backgroundColor: "#F6F9FC",
                          width: 50,
                          padding: 5,
                        }}
                      />
                    </td>
                    <td>
                      <input
                        className="formInput"
                        value={deliveryData[item.deliveryPrice]}
                        onChange={(e) =>
                          setDeliveryData({
                            ...deliveryData,
                            [item.deliveryPrice]: e.target.value,
                          })
                        }
                        style={{
                          borderWidth: 0,
                          backgroundColor: "#F6F9FC",
                          width: 50,
                          padding: 5,
                        }}
                      />
                    </td>
                    <td>
                      <select
                        className="formInput"
                        value={deliveryData[item.schedule]}
                        onChange={(e) =>
                          setDeliveryData({
                            ...deliveryData,
                            [item.schedule]: e.target.value,
                          })
                        }
                        style={{
                          borderWidth: 0,
                          backgroundColor: "#F6F9FC",
                          width: 100,
                          padding: 5,
                        }}
                      >
                        <option value="allDay">All day 24h</option>
                        <option value="custom">Custom</option>
                      </select>
                    </td>
                    <td>
                      {deliveryData[item.schedule] === "custom" && (
                        <div className="flex gap-2">
                          <input
                            type="time"
                            className="formInput"
                            value={deliveryData[item.time]?.[0] ?? ""}
                            onChange={(e) =>
                              setDeliveryData({
                                ...deliveryData,
                                [item.time]: [
                                  e.target.value,
                                  deliveryData[item.time]?.[1] ?? "",
                                ],
                              })
                            }
                            style={{
                              borderWidth: 0,
                              backgroundColor: "#F6F9FC",
                              width: 100,
                              padding: 5,
                            }}
                          />
                          <input
                            type="time"
                            className="formInput"
                            value={deliveryData[item.time]?.[1] ?? ""}
                            onChange={(e) =>
                              setDeliveryData({
                                ...deliveryData,
                                [item.time]: [
                                  deliveryData[item.time]?.[0] ?? "",
                                  e.target.value,
                                ],
                              })
                            }
                            style={{
                              borderWidth: 0,
                              backgroundColor: "#F6F9FC",
                              width: 100,
                              padding: 5,
                            }}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </table>
          </div>

          <Button
            onClick={() => handleDeliverySubmit()}
            style={{
              cursor: "pointer",
              backgroundColor: "#4C9F6F",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              padding: 10,
              color: "white",
              borderWidth: 0,
              marginTop: 10,
              marginLeft: 40,
              width: 130,
            }}
          >
            Guardar Cambios
          </Button>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
          >
            {vendorGet && (
              <table style={{ width: "80%" }}>
                <tr
                  style={{
                    textAlign: "left",
                  }}
                >
                  <th>Habilitar/Deshabilitar</th>
                  <th>Método de Pago</th>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <ToggleSlider
                      active={vendorGet?.adminPaymentType?.snipeChecked}
                      onToggle={handleUploadSimpe}
                      draggable={true}
                    />
                  </th>
                  <td>SINPE Móvil</td>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <ToggleSlider
                      active={vendorGet?.adminPaymentType?.transferChecked}
                      onToggle={handleUploadTransfer}
                      draggable={true}
                    />
                  </th>
                  <td>Transferencia bancaria</td>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <ToggleSlider
                      active={vendorGet?.adminPaymentType?.onDeliveryChecked}
                      onToggle={handleUploadOnDelivery}
                      draggable={true}
                    />
                  </th>
                  <td>Pago en Efectivo</td>
                </tr>
              </table>
            )}
          </div>

          <button
            onClick={() => handlePaymentSubmit()}
            style={{
              cursor: "pointer",
              backgroundColor: "#4C9F6F",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              padding: 10,
              color: "white",
              borderWidth: 0,
              marginTop: 10,
              marginLeft: 40,
              width: 130,
            }}
          >
            Guardar Cambios
          </button>
        </Grid>
      </Grid>
    </div>
  );
}

const Styles = {
  customOverlay: {
    background: "rgba(36, 123, 160, 0.7)",
    borderRadius: 10,
  },
  customModal: {
    borderRadius: 10,

    minHeight: "0%",
    minWidth: "350px",
    maxWidth: "150%",
  },
};
