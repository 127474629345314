import React, { useContext, useState, useEffect } from "react";
import { Grid, Chip } from "@mui/material";
import axios from "../axios";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import AdminDashBoard from "../AdminComponents/AdminDashBoard";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import BasiCardTable from "./BasiCardTable";
const drawerWidth = 240;

export default function BasiCard(props) {
    
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const [activeTab, setActiveTabs] = useState("BasiCard");
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
   

    const navigation = useNavigate();
    const [vendor, setVendor] = useState([]);
    const [loading, setLoading] = useState();

    const drawer = (
        <Grid   md={2}  sx={12}
          style={{ width: "100%", }}
        >
          <div style={{ marginTop: 90 }}>
            <AdminDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
          </div>
        </Grid>
      );
    
      const container =
        window !== undefined ? () => window().document.body : undefined;
        const [transactions, setTrasactions] = useState([])
        const [completed, setCompleted] = useState(false)
        const [pending, setPending] = useState(false)
        const [all, setAll] = useState(true)

        const handleClickPending = () => { 
          setCompleted(false)
          setPending(true)
          setAll(false)
        }
        const handleClickCompleted = () => { 
          setPending(false)
          setCompleted(true)
          setAll(false)
        }
        const handleClickAll = () => {
          setAll(true)
          setPending(false)
          setCompleted(false)
        }

        useEffect(() => {
            const getBalance = () => {
              axios
                .get(`/api/v1/basiCard/getAllTransactions`)
                .then((res) => {
                  if(all) setTrasactions(res.data.transactions.all)
                  if(pending) setTrasactions(res.data.transactions.pending)
                  if(completed) setTrasactions(res.data.transactions.completed)
                })
                .catch((err) => {
                  console.log(err);
                });
            };       
            getBalance();
          });


  return (
    <>
    <Box sx={{ display: "flex", background: "#F6F9FC", width: "100%" }}>
    <AdminNavbar handleDrawerToggle={handleDrawerToggle} />
      <IconButton
        style={{
          marginLeft: 10,
          position: "absolute",
          zIndex: 100,
          top: 12,
          left: -10,
        }}
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { sm: "none", md: "none", lg: "none" } }}
      >
        <MenuIcon />
      </IconButton>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
            <Grid item md={12} xs={12}  style={{ minHeight: "90vh", background: "#F6F9FC" }} >
                <Grid md={12} xs={12}>
                <h2
                    style={{
                    fontSize: "22px",
                    fontWeight: 'bold',
                    color: "#000",
                    marginTop: '100px',
                    marginBottom: '30px',
                    }}
                >
                    Transacciones con Basi Card
                </h2>
                </Grid>

                <Grid md={12} xs={12} style={{marginBottom: '15px', marginLeft:'10px'}}>
                  <Chip style={!all ? styles.chip : styles.chipPress} label="Todas" onClick={handleClickAll} />
                  <Chip style={!pending ? styles.chip : styles.chipPress} label="Pendientes" onClick={handleClickPending} />
                  <Chip style={!completed ? styles.chip : styles.chipPress} label="Completadas" onClick={handleClickCompleted} />
              
                </Grid>

                <Grid  item  style={{ width: "100%" }}  md={12} xs={10} >
                <BasiCardTable transactions={transactions}/>
            </Grid>                
          </Grid>

        

        )}

        </Box>
    </Box>
    </>
  );
}


const styles = { 
    tableHeading: { background: "#529f6f", height:'1.5px', width:'100%'},
    
    mdTable: { 
        background: "white", 
        borderSpacing: "50px 8px", 
        borderCollapse: "separate", 
        paddingLeft:'5px',
        marginLeft:'5px'  
    },
    mdTableMobile: { 
        background: "white", 
        borderSpacing: "20px 8px", 
        borderCollapse: "separate", 
        marginTop: '-65px'   
    },
    
    tableBody: { 
        color: "black",
        fontSize: 12,
        textAlign: 'center',
        margin: 'auto',
    },
    tableTxt: {
        fontSize: 12,
        color: "black",
        padding: "2px",
        margin: 'auto',
        textAlign: 'center',
        fontWeight:'200'
    
      },
      valueTxt: {
        fontSize: 12,
        color: "#222",
      },
      hightLightTxt: {
        fontSize: 12,
        color: "#AD6D49",
        margin: 0,
      },

    tittle: { 
        color: "#4B566B", 
        fontSize: 15, 
        fontWeight: "bold", 
        paddingLeft: '10px',
        marginTop: '20px',
      },

    tittleMobile: { 
        color: "#4B566B", 
        fontSize: 15, 
        fontWeight: "bold", 
        paddingLeft: '3px',
        marginTop: '10px',
      },

    subTittle: { 
        color: "#4B566B", 
        fontSize: 13, 
        fontWeight: "bold", 
        paddingLeft: '10px',
      },
      
      
    confirmando: {
        width: 76,
        height: 17,
        borderRadius: 5,
        backgroundColor: "#eae7f0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 11,
        padding: 15,
        textAlign: "center",
        fontWeight: 'bold',
        color: '#423363'
      },
      container: { 
        background: "#f2f2f4", 
        width:'100%', 
        borderRadius: 8,
        borderWidth: 2,
        borderColor: 'white',       
        padding: '10px',
        margin: '5px',  
        height:'160px'    
    },
    styleImg: {
      position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 300,
    bgcolor: "background.paper",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    },
    chip : {
      backgroundColor: 'gray',
      color: 'white',
      marginRight:'10px'
    },
    chipPress : {
      backgroundColor: 'green',
      color: 'white',
      marginRight:'10px'
    }
  };