import PropTypes from "prop-types";
import React, { useState } from "react";
import { Grid } from "@mui/material";
import AdminMoneyStatus from "../AdminComponents/AdminMoneyStatus";
import AdminBarChart from "../AdminComponents/AdminBarChart";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/Wallet";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import Colors from "../Theme/Colors";
import AdminOrderStatus from "../AdminComponents/AdminOrderStatus";
import { useNavigate } from "react-router-dom";
import AllProductTable from "../components/AllProductTable";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import AdminDashBoard from "../AdminComponents/AdminDashBoard";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import VendorTable from "../VendorComponents/VendorTable";
import WarehouseTable from "../components/WareHouseTable";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import axios from "../axios";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";

const drawerWidth = 240;

function AllProducts(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigation = useNavigate();
  const [activeTab, setActiveTabs] = useState("Producto");
  const [warehouse, setWarehouse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProdcuts] = useState();

  const getAllProducts = async () => {
    setLoading(true);
    axios
      .get("/api/v1/products")
      .then((res) => {
        console.log(res);
        setProdcuts(res?.data?.product);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    getAllProducts();
    setLoading(false);
  }, []);

  const drawer = (
    <Grid
      md={2}
      lg={2}
      sm={2}
      sx={12}
      style={{
        width: "100%",
      }}
    >
      <div style={{ marginTop: 90 }}>
        <AdminDashBoard activeTab={activeTab} setActiveTabs={setActiveTabs} />
      </div>
    </Grid>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#F6F9FC",
        width: "100%",
        height: "100vh",
      }}
    >
      <AdminNavbar handleDrawerToggle={handleDrawerToggle} />
      <IconButton
        style={{
          marginLeft: 10,
          position: "absolute",
          zIndex: 100,
          top: 12,
          left: -10,
        }}
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { sm: "none", md: "none", lg: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{
            sx: { height: "100%", background: "#F6F9FC" },
          }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              height: "90%",
              marginTop: 10,
              borderWidth: 0,
              background: "#F6F9FC",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <Grid
            style={{ minHeight: "100vh", backgroundColor: "#F6F9FC" }}
            item
            md={12}
            lg={10}
            sm={12}
            xs={12}
          >
            <Grid>
              <Grid md={12} xs={12}>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#000",
                    marginBottom: -10,
                    marginTop: 100,
                  }}
                >
                  Producto
                </h2>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                }}
                md={11}
                xs={12}
              >
                <Grid
                  md={2.5}
                  xs={6}
                  onClick={() => navigation("/AllProductDelete")}
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#4C9F6F",
                    padding: 10,
                    borderRadius: 10,
                    marginRight: 30,
                    cursor: "pointer",
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                    marginBottom: -10,
                  }}
                >
                  Borrar Productos
                </Grid>
                <Grid
                  md={2.5}
                  xs={6}
                  onClick={() => navigation("/uploadExcel")}
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#4C9F6F",
                    padding: 10,
                    borderRadius: 10,
                    marginRight: 30,
                    cursor: "pointer",
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                    marginBottom: -10,
                  }}
                >
                  Subir Catálogo
                </Grid>
                <Grid
                  md={2.5}
                  xs={6}
                  onClick={() => navigation("/AddNewProduct")}
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    color: "white",
                    backgroundColor: "#4C9F6F",
                    padding: 10,
                    borderRadius: 10,
                    marginRight: 30,
                    cursor: "pointer",
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                    marginBottom: -10,
                  }}
                >
                  Agregar Producto
                </Grid>
              </Grid>
              <Grid item md={12} lg={10} sm={12} xs={12}>
                {products && <AllProductTable data={products} />}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

AllProducts.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default AllProducts;

const balanceStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  todayOrders: {
    backgroundColor: Colors.seaGray,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  MonthlyOrders: {
    backgroundColor: Colors.seaBlue,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  totalOrders: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  iconStyle: {
    color: "white",
    fontSize: 18,
    fontWeight: "600",
  },
  textStyle: { color: "white", fontSize: 17, fontWeight: "600" },
  balanceStyle: { color: "#4B566B", fontSize: 25, fontWeight: "700" },
};
const orderStyles = {
  orderMainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  orderChildContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    display: "flex",

    alignItems: "center",
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  iconStyleTotal: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#F7DABD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStylePending: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#C3DDFD",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleProcessing: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#AFECEF",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  iconStyleDelivered: {
    color: "black",
    fontSize: 10,
    backgroundColor: "#BCF0DA",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    padding: 9,
  },
  orderTextStyle: {
    color: "black",
    fontSize: 14,
    fontWeight: "600",
  },
  orderQuantity: { color: "#4B566B", fontSize: 17, fontWeight: "700" },
};
