import { Divider, Grid, TextField as MUITextField } from "@mui/material";
import React, { useState } from "react";
import { parseISO } from "date-fns";
import { TextField } from "formik-material-ui";
import { DateTimePicker } from "formik-mui-x-date-pickers";
import axios from "../axios";
import "react-phone-number-input/style.css";
import { Field, Formik, Form } from "formik";
import AdminNavbar from "../AdminComponents/AdminNavbar";
import Box from "@mui/material/Box";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Avatar from "@mui/material/Avatar";
import { IconButton } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Creatable from "react-select/creatable";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

import Colors from "../Theme/Colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};
function EditWarehouseProduct() {
  const location = useLocation();
  const params = location.state.productData;
  const warehoused = location.state.warehouseData;
  console.log(location, params, "locationlocation");
  const [category, setCategory] = useState();
  const [subCategory1, setSubCategory1] = useState(["select category"]);
  const [aisle, setAisle] = React.useState("");
  const [allAisle, setAllAisle] = useState();
  const [allCategory, setAllCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [warehouse, setWarehouse] = useState();
  const [wID, setWId] = useState([""]);
  const [defalutValue, setDefaultValue] = useState([""]);
  const [storeImage, setStoreImage] = useState(null);
  const [img, setImg] = useState(null);

  const [selectedPromo, setSelectedPromo] = useState("regular-promo");

  console.log(params, "product Params");

  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(e.target.result);
        setStoreImage(e.target.result);
      };
      // let img = URL.createObjectURL(event.target.files[0])
      // console.log(img)
      // setStoreImage(img)
    }
  };

  const promoUpdatehandler = (event) => {
    console.log(event.target.value);
    setSelectedPromo(event.target.value);
  };

  useEffect(() => {
    async function getWarehouser() {
      const req = await axios.get("/api/v1/warehouses");
      setWarehouse(req.data.warehouse);
      // console.log(req.data.warehouse)
      req.data.warehouse.forEach((doc) => {
        // console.log("doc")
        if (params.wId[0] == doc._id) {
          setDefaultValue(doc);
          console.log(doc, "doc");
        }
      });
    }
    getWarehouser();
  }, [params.wId]);
  const [open, setOpen] = React.useState(false);
  const notify = (e) => toast(e);

  const handleSubmit = async (values) => {
    console.log(values, "myidmyidmyidmyidmyid");
    values.wId = [];
    if (wID.length !== 0 && wID[0] !== "") {
      values.wId.push(wID);
    } else {
      values.wId.push(defalutValue?._id);
    }
    values.img = img === "" || !img ? params.images : img;
    console.log(values.promoType);
    console.log(values, "values");
    const updatedValues = {
      _id: params._id,
      pId: params.pId,
      wId: values.wId,
      name: values.productName,
      size: values.size,
      stock: Number(values.stock),
      price: values.price,
      images: values.img,
      description: values.productDescription,
      aisle: aisle ? aisle.label?.toUpperCase() : params.aisle?.toUpperCase(),
      category: allCategory
        ? allCategory.label?.toUpperCase()
        : params.category?.toUpperCase(),
      subCategory: subCategory
        ? subCategory.label?.toUpperCase()
        : params.subCategory?.toUpperCase(),
      priceChopDifficulty: values.priceChopDifficulty,
      created: created,
      storyEnabled: values.storyEnabled,
      storyExpiredOn: values.storyExpiredOn,
    };
    await axios
      .post(
        `/api/v1/warehouse/editWarehouseProduct/${warehoused._id}`,
        updatedValues
      )
      .then((res) => {
        console.log(res.data, "mayresponsedata");
        notify("Warehouse product edited successfully");
      })
      .catch((err) => {
        console.log(err);
      });

    try {
      const deal = {
        productId: params.pId,
        product: {
          _id: params._id,
          pId: params.pId,
          name: values.productName,
          size: values.size,
          price: values.price,
          images: values.img,
          description: values.productDescription,
        },
        promoType: selectedPromo,
        regularPromoExpiration: values.regularPromoExpiration,
        regularPromoValue: values.regularPromoValue,
        teamPromoExpiration: values.teamPromoExpiration,
        teamPromoValue: values.teamPromoValue,
        teamSize: values.dealTeamSize,
        teamsQuantity: values.dealTeamsQuantity,
        priceChopDifficulty: values.priceChopDifficulty,
        //expirationDate: values.dealExpiration
      };

      // if (values.dealTeamSize && values.dealTeamsQuantity) {
      //   deal.value = {
      //     percentage: values.dealValue,
      //     teamSize: values.dealTeamSize,
      //     teamsQuantity: values.dealTeamsQuantity,
      //   };
      //   deal.type = "team";
      // } else {
      //   deal.value = values.dealValue;
      //   deal.type = "percentage";
      // }

      await axios
        .post(`/api/v1/warehouse/setProductDeal/${warehoused._id}`, deal)
        .then((res) => {
          console.log(res, "Product offer created successfully");
        });
    } catch (error) {
      console.error(error);
    }
  };

  const [created, setCreated] = React.useState(false);
  function handleAisleCreated(opt, meta) {
    console.log(opt, meta);

    setAisle(opt);
    if (meta.action == "create-option") {
      setCreated(true);
    } else {
      getCategory(opt.label);
    }
  }
  const getCategory = async (val) => {
    let al = [];
    console.log(val, "inside function ");
    axios.get(`/api/v1/category/${val.toUpperCase()}`).then((res) => {
      console.log(res);
      res?.data?.cat.forEach((doc) => {
        al.push({ label: doc.label, value: doc.value });
      });
      console.log(al, "cat");
      setCategory(al);
    });
  };

  const handleCategoryget = async (opt, meta) => {
    setAllCategory(opt);

    let al = [];
    if (meta.action != "create-option") {
      console.log(opt, "inside function ");
      axios
        .get(`/api/v1/subCategory/${opt.label.toUpperCase()}`)
        .then((res) => {
          console.log(res);
          res?.data?.subCat.forEach((doc) => {
            al.push({ label: doc.label, value: doc.value });
          });
          console.log(al, "cat");
          setSubCategory(al);
        });
    } else {
      setCreated(true);
    }
  };
  const getAisle = async () => {
    let al = [];
    console.log("aisle");
    axios.get("/api/v1/aisle").then((res) => {
      console.log(res.data);
      res?.data?.aisle.forEach((doc) => {
        al.push({ label: doc.label, value: doc.value });
      });
      console.log(al);
      setAllAisle(al);
    });
  };
  useEffect(() => {
    getAisle();
  }, []);
  useEffect(() => {
    if (params.deal?.type === "regular") {
      setSelectedPromo("regular-promo");
    } else if (params.deal?.type === "team") {
      setSelectedPromo("team-promo");
    } else if (params.deal?.type === "chop") {
      setSelectedPromo("chop-promo");
    }
  }, [params]);
  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 10);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      padding: 20,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  return (
    <Grid>
      <div>
        <Toaster />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Alert severity="success">Your product is Edited </Alert>
          </Box>
        </Modal>
      </div>
      <AdminNavbar />
      <Formik
        initialValues={{
          productName: params.name,
          size: params.size,
          stock: params.stock,
          price: params.price,
          sampleImg: "",
          productDescription: params.description,
          promoType: selectedPromo,
          regularPromoExpiration: params.deal?.expirationDate
            ? parseISO(params.deal?.expirationDate)
            : new Date(),
          regularPromoValue:
            params.deal?.type === "regular"
              ? params.deal?.value
              : params.deal?.value?.percentage || 0,
          teamPromoExpiration: params.deal?.expirationDate
            ? parseISO(params.deal?.expirationDate)
            : new Date(),
          teamPromoValue:
            params.deal?.type === "team"
              ? params.deal?.value
              : params.deal?.value?.percentage || 0,
          dealTeamSize: params.deal?.value?.teamSize || 0,
          dealTeamsQuantity: params.deal?.value?.teamsQuantity || 0,
          priceChopDifficulty: params.priceChopDifficulty || 0,
          storyEnabled: params.storyEnabled || false,
          storyExpiredOn: params.storyExpiredOn
            ? parseISO(params.storyExpiredOn)
            : new Date(),
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <div style={{ marginTop: 100 }}>
            <div
              style={{
                fontWeight: "700",
                fontSize: 23,
                marginLeft: 5,
                color: "#4C9F6F",
              }}
            >
              Editar producto
            </div>
            <Form>
              <div
                style={{
                  padding: 20,
                  borderBottomWidth: 1,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: 23,
                  }}
                >
                  {storeImage && (
                    <Avatar
                      src={storeImage}
                      style={{
                        display: "flex",
                        width: 100,
                        height: 100,

                        alignSelf: "center",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        justifySelf: "center",
                      }}
                    />
                  )}

                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleChangeImage}
                    id="contained-button-file"
                  />
                  <label
                    style={{ alignSelf: "center" }}
                    htmlFor="contained-button-file"
                  >
                    <IconButton
                      style={{ backgroundColor: "lightgray" }}
                      component="span"
                    >
                      <AddPhotoAlternateIcon />
                    </IconButton>
                  </label>
                </div>

                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="productName"
                  name="productName"
                  // defaultValue="zipCode"
                  values={values.productName}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Nombre del Producto "
                  // variant="filled"
                />

                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="size"
                  name="size"
                  values={values.size}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Tamaño del Producto "
                  // variant="filled"
                />
                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="stock"
                  name="stock"
                  values={values.stock}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Stock del Producto "
                  // variant="filled"
                />
                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="productDescription"
                  name="productDescription"
                  // defaultValue="zipCode"
                  values={values.productDescription}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Descripción del Producto "
                  // variant="filled"
                />
                <Field
                  component={TextField}
                  type="text"
                  onChange={handleChange}
                  id="price"
                  name="price"
                  // defaultValue="zipCode"
                  values={values.price}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Precio del Producto"
                  // variant="filled"
                />

                <FormControl sx={{ width: "45%", margin: 0.5 }}>
                  {allAisle && (
                    <Creatable
                      width="100%"
                      height="500%"
                      menuColor={Colors.lightGreen}
                      styles={customStyles}
                      placeholder={"Seleccionar Pasillo"}
                      options={allAisle}
                      onChange={(opt, meta) => handleAisleCreated(opt, meta)}
                    />
                  )}
                </FormControl>

                <FormControl sx={{ width: "45%", margin: 0.5 }}>
                  {(category || created) && (
                    <Creatable
                      width="100%"
                      height="500%"
                      menuColor={Colors.lightGreen}
                      styles={customStyles}
                      placeholder={"Seleccionar Categoría "}
                      options={category}
                      onChange={(opt, meta) => handleCategoryget(opt, meta)}
                    />
                  )}
                </FormControl>
                <FormControl sx={{ width: "45%", margin: 0.5 }}>
                  {(subCategory || created) && (
                    <Creatable
                      width="100%"
                      height="500%"
                      menuColor={Colors.lightGreen}
                      styles={customStyles}
                      placeholder={"Seleccionar Subcategoría"}
                      options={subCategory}
                      onChange={(opt) => setSubCategory(opt)}
                    />
                  )}
                </FormControl>
                <Divider style={{ marginBottom: 20 }} />

                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  htmlFor="discount-1"
                >
                  <Field
                    type="radio"
                    id="discount-1"
                    name="promo"
                    value="regular-promo"
                    checked={selectedPromo === "regular-promo"}
                    onChange={promoUpdatehandler}
                  />
                  <h3
                    style={{
                      color: "#4c9f6f",
                      marginLeft: "10px",
                    }}
                  >
                    Promociones regulares
                  </h3>
                </label>
                <Field
                  component={DateTimePicker}
                  renderInput={(props) => (
                    <MUITextField
                      style={{
                        borderWidth: 1,
                        color: "green",
                        borderColor: "black",
                        width: "45%",
                        margin: 5,

                        marginBottom: 10,
                      }}
                      {...props}
                      label="Expiración de Promo "
                    />
                  )}
                  id="regularPromoExpiration"
                  name="regularPromoExpiration"
                  disabled={selectedPromo !== "regular-promo"}
                />
                <Field
                  component={TextField}
                  type="number"
                  onChange={handleChange}
                  id="regularPromoValue"
                  name="regularPromoValue"
                  disabled={selectedPromo !== "regular-promo"}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Porcentaje de Promo (%) "
                />
                <Divider style={{ marginBottom: 20, marginTop: 20 }} />
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  htmlFor="discount-2"
                >
                  <Field
                    type="radio"
                    id="discount-2"
                    name="promo"
                    checked={selectedPromo === "team-promo"}
                    value="team-promo"
                    onChange={promoUpdatehandler}
                  />
                  <h3
                    style={{
                      color: "#4c9f6f",
                      marginLeft: "10px",
                    }}
                  >
                    Compras en equipo
                  </h3>
                </label>
                <Field
                  component={DateTimePicker}
                  renderInput={(props) => (
                    <MUITextField
                      style={{
                        borderWidth: 1,
                        color: "green",
                        borderColor: "black",
                        width: "45%",
                        margin: 5,

                        marginBottom: 10,
                      }}
                      {...props}
                      label="Expiración de Promo "
                    />
                  )}
                  id="teamPromoExpiration"
                  name="teamPromoExpiration"
                  disabled={selectedPromo !== "team-promo"}
                />
                <Field
                  component={TextField}
                  type="number"
                  onChange={handleChange}
                  id="teamPromoValue"
                  name="teamPromoValue"
                  disabled={selectedPromo !== "team-promo"}
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,

                    marginBottom: 10,
                  }}
                  label="Porcentaje de Promo (%) "
                />
                <Field
                  component={TextField}
                  type="number"
                  onChange={handleChange}
                  id="dealTeamSize"
                  name="dealTeamSize"
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,
                    marginBottom: 10,
                  }}
                  label="Tamaño de Equipo"
                  disabled={selectedPromo !== "team-promo"}
                />
                <Field
                  component={TextField}
                  type="number"
                  onChange={handleChange}
                  id="dealTeamsQuantity"
                  name="dealTeamsQuantity"
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,
                    marginBottom: 10,
                  }}
                  disabled={selectedPromo !== "team-promo"}
                  label="Cantidad de Equipos "
                />
                <Divider style={{ marginBottom: 20, marginTop: 20 }} />
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  htmlFor="discount-3"
                >
                  <Field
                    type="radio"
                    id="discount-3"
                    name="promo"
                    checked={selectedPromo === "chop-promo"}
                    value="chop-promo"
                    onChange={promoUpdatehandler}
                  />
                  <h3
                    style={{
                      color: "#4c9f6f",
                      marginLeft: "10px",
                    }}
                  >
                    Corte de precio
                  </h3>
                </label>
                <Field
                  component={TextField}
                  type="number"
                  onChange={handleChange}
                  id="priceChopDifficulty"
                  name="priceChopDifficulty"
                  style={{
                    borderWidth: 1,
                    color: "green",
                    borderColor: "black",
                    width: "45%",
                    margin: 5,
                    marginBottom: 10,
                  }}
                  disabled={selectedPromo !== "chop-promo"}
                  label="Dificultad Price Chop "
                />

                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  htmlFor="storyEnabled"
                >
                  <Field
                    type="checkbox"
                    id="storyEnabled"
                    name="storyEnabled"
                  />
                  <h3
                    style={{
                      color: "#4c9f6f",
                      marginLeft: "10px",
                    }}
                  >
                    Habilitar historia
                  </h3>
                </label>
                <Field
                  component={DateTimePicker}
                  renderInput={(props) => (
                    <MUITextField
                      style={{
                        borderWidth: 1,
                        color: "green",
                        borderColor: "black",
                        width: "45%",
                        margin: 5,

                        marginBottom: 10,
                      }}
                      {...props}
                      label="Expiración de historia"
                    />
                  )}
                  id="storyExpiredOn"
                  name="storyExpiredOn"
                  disabled={!values?.storyEnabled}
                />

                <button
                  type="submit"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#4C9F6F",
                    width: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    padding: 10,
                    color: "white",
                    borderWidth: 0,
                    marginTop: 30,
                  }}
                >
                  Editar producto
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </Grid>
  );
}

export default EditWarehouseProduct;
